/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
            <span class="primary-color">We support the following file formats:&nbsp;&nbsp;Text, Csv, XLSX, PDF, HTML, JSON</span>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <!-- Upload File -->
                <div class="col-md-12 mb-3 ">
                  <label for="validationreplace_keywords">{{cvUploadLabel}}</label>
                  <div class="form-row form-control" >
                    <div class="col-md-12 mb-3 custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        @change="onFileChange"
                        required/>
                        <label class="custom-file-label" for="validationreplace_keywords">{{cvUploadLabel}}</label>
                    </div>
                  </div>
                </div><!-- Upload File -->

                <!-- Web Url -->
                <div class="col-md-12 mb-3">
                  <label for="validationurl">
                    {{cvUrlLabel}}</label
                  >
                  <input
                    v-model="vmChatBotDataSrcFormData.url"
                    type="text"
                    class="form-control"
                    required
                  />
                </div><!-- Web Url -->

                <!-- Replace Keywords with GIDE.AI -->
                <div class="col-md-12 mb-3">
                  <label for="validationreplace_keywords">
                    {{cvReplaceKeywordsLabel}}</label
                  >
                  <input
                    v-model="vmChatBotDataSrcFormData.replace_keywords"
                    placeholder="Jamboree, Tutela etc"
                    type="text"
                    class="form-control"
                    required
                  />
                </div><!-- Replace Keywords with GIDE.AI -->

                <!-- Is Json checkbox -->
                <div class="col-md-12 mb-3">
                  <input v-model="isJson" type="checkbox" id="isJson" style="margin-right:5px" />
                  <label for="isJson" style="color:red">File Contains Json</label>
                </div><!-- Is Json checkbox -->

                <!-- Data Schema(only for JSON) -->
                <div class="col-md-12 mb-3" v-if="isJson">
                  <label for="validationdata_schema">
                    {{cvDataSchemaLabel}}</label
                  >
                  <textarea v-model="vmChatBotDataSrcFormData.data_schema" type="json" @input="formatJSON" class="form-control textarea" id="json">
                  </textarea>
                  <span class="primary-color" v-if="invalidJSONMsg">{{invalidJSONMsg}}</span>
                </div><!-- Data Schema(only for JSON) -->
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="chatbot_data_srcAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { ChatBotDataSrcs } from "../../../FackApi/api/ChatBotDataSrc"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "ChatBotDataSrcAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Upload Asset",
      cvSubmitBtn: "Add",
      cvDataSchemaLabel: "data schema",
      cvUploadLabel: "Upload File",
      cvUrlLabel: "url",
      cvReplaceKeywordsLabel: "Replace Brand Keywords with GIDE.AI",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "ChatBotDataSrc Added",
      vmChatBotDataSrcFormData: Object.assign({}, this.initFormData()),
      vmChatBotDataSrcDesc: null,
      vmChatBotDataSrcType: null,
      invalidJSONMsg: null,
      trainingFile: null,
      isJson: false
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "data_schema": "",
        "url": "",
        "replace_keywords": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmChatBotDataSrcFormData) {
          if (!this.vmChatBotDataSrcFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * chatbot_data_srcAdd
     */
    async chatbot_data_srcAdd () {
      try {
        this.cvLoadingStatus = true
        if (this.isJson && !this.vmChatBotDataSrcFormData.data_schema) {
          this.toastMsg = "Please provide the schema for the json data"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.vmChatBotDataSrcFormData.file_to_train = this.trainingFile
        let chatbotDataSrcAddResp = await ChatBotDataSrcs.chatbot_data_srcAdd(this, this.vmChatBotDataSrcFormData)
        await ApiResponse.responseMessageDisplay(this, chatbotDataSrcAddResp)
        if (chatbotDataSrcAddResp && !chatbotDataSrcAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseChatBotDataSrcAddModal", chatbotDataSrcAddResp.resp_data)
        }
        this.vmChatBotDataSrcFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * formatJSON
     */
    formatJSON () {
      try {
        let unformattedJSON = document.getElementById("json").value
        let obj = JSON.parse(unformattedJSON)
        if (obj) {
          this.invalidJSONMsg = false
        }
        let formattedJSON = JSON.stringify(obj, undefined, 4)
        this.vmChatBotDataSrcFormData.data_schema = formattedJSON
      }
      catch (err) {
        this.invalidJSONMsg = "Invalid JSON"
      }
    },
    /**
     * onFileChange
     */
    onFileChange (e) {
      try {
        console.log(e.target.files)
        this.trainingFile = e.target.files[0]
        if (!this.trainingFile) {
          return null
        }
      }
      catch (err) {
        console.error("Exception occurred at onFileChange() and Exception:", err.message)
      }
    }
  }
}
</script>

<style scoped>
  .textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
  .custom-file-label{
    border: 0px;
  }
</style>
